<template>
  <section>
    <div class="columns is-5-mobile is-3-desktop is-multiline is-centered">
      <div class="column is-10 is-10-mobile">
        <h1 class="title">Promociones</h1>
        <h2 class="subtitle">
          Los mejores paquetes para tus eventos especiales
        </h2>
      </div>
      <div
        class="column is-5 is-10-mobile"
        v-for="promo in promotions"
        :key="promo.img"
      >
        <div class="card card-shadow">
          <div class="card-image">
            <figure class="image has-bordered">
              <img
                class="is-bordered"
                :src="getImgUrl(promo.img)"
                :alt="promo.title"
                :title="promo.title"
              />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-content">
                <h3 class="title is-4">
                  {{ promo.title }} - ${{ promo.price }}
                </h3>
                <p class="subtitle is-6">{{ promo.desc }}</p>
              </div>
            </div>

            <div class="content">
              <ul>
                <li v-for="p in promo.content" :key="p">{{ p }}</li>
              </ul>
            </div>
            <footer class="card-footer">
              <a
                :href="getWhatsappUrl(promo)"
                class="card-footer-item button is-primary"
                target="_blank"
              >
                <span class="icon is-small">
                  <i class="fab fa-whatsapp"></i>
                </span>
                <span>Ordenar</span>
              </a>
              <a
                href="tel:+526674138519"
                class="card-footer-item button is-link"
                target="_blank"
              >
                <span class="icon is-small">
                  <i class="fas fa-phone-alt"></i>
                </span>
                <span>Llamar</span>
              </a>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Promotions",
  methods: {
    getImgUrl(img) {
      return `/static/images/promotions/${img}`;
    },
    getWhatsappUrl(promo) {
      let message = `Hola, quiero pedir el paquete ${
        promo.title
      } que contiene: \r\n\r\n -${promo.content.join("\r\n-")}`;
      return `https://wa.me/+526674138519?text=${encodeURIComponent(message)}`;
    },
  },
  data() {
    return {
      promotions: [
        /*
        {
          title: "Posada Bronce",
          desc: "$200 por persona (+ 10% del total por el servicio de evento)",
          content: [
            "180 gr de pulled pork ahumado 10 hrs acompañado de papas cambray gratinadas al Lemmon pepper y elote asado a la mantequilla.",
            "1 limonada natural.",
            "Guacamole al centro.",
            "Panecillos con mantequilla de hierbas aromáticas.",
          ],
          img: "navidad-bronce.png",
          price: 200,
        },
        {
          title: "Posada plata",
          desc: "$250 por persona (+ 10% del total por el servicio de evento)",
          content: [
            "150 de New York al grill en tiras y 100 gr de Brisket ahumado, acompañados de queso fundido y guacamole de la casa.",
            "1 limonada natural.",
            "Ensalada César al centro.",
            "Tortillas, salsas tatemadas y panecillos al centro.",
          ],
          img: "navidad-plata.png",
          price: 250,
        },
        {
          title: "Posada Oro",
          desc: "$300 por persona (+ 10% del total por el servicio de evento)",
          content: [
            "150 gr de arrachera y 150 gr de picaña (en tiras) acompañados de guacamole y quesofundido.",
            "1 limonada natural.",
            "Tortillas, salsas tatemadas y panecillos al centro.",
            "Ensalada César al centro.",
          ],
          img: "navidad-oro.png",
          price: 300,
        },
        {
          title: "Posada VIP",
          desc: "$350 por persona (+ 10% del total por el servicio de evento)",
          content: [
            "150 gr de rib eye y 150 gr de picaña (en tiras) acompañados de guacamole y queso fundido.",
            "1 limonada natural.",
            "Ensalada César al centro.",
            "Tortillas, salsas tatemadas y panecillos al centro.",
          ],
          img: "navidad-vip.png",
          price: 350,
        },
        {
          title: "Vive el Amor - Prime",
          desc: "",
          content: [
            "1 BEBIDA POR PERSONA",
            "Ginebra de frutos rojos, copa de vino tinto, cerveza, refresco o limonada.",
            "GUACAMOLE CON",
            "CHICHARRONES DE PICAÑA",
            "Recién hechos, con un toque de salsa de chile de árbol y cacahuates, totopos para acompañar.",
            "ENSALADA CESAR",
            "Frescas hojas de lechugas, con queso parmesano, crutones, tomates y aderezo cèsar.",
            "ARRACHERA DE LA COSTA",
            "Jugosa Arrachera bañada de queso fundido con camarones salteados con el sazón de la casa y perejil.",
            "RIB EYE DE LA CASA",
            "Suave Rib Eye 350 gr acompañado cremoso dip de hierbas aromáticas y jalapeño al grill relleno de queso crema y tocino.",
            "CHEESECAKE DE ZARZAMORAS",
          ],
          img: "happy-valentine-day3.jpeg",
          price: 1250,
        },
        {
          title: "Vive el Amor - Select",
          desc: "",
          content: [
            "1 BEBIDA POR PERSONA",
            "Ginebra de frutos rojos, copa de vino tinto, cerveza, refresco o limonada.",
            "PAPAS SAZONADAS",
            "ESPECIALES + GUACAMOLE",
            "Bañadas de abundante queso amarillo coronado con nuestro delicioso brisket ahumado, trozos de tocino y perejil.",
            "ENSALADA CESAR",
            "Frescas hojas de lechugas, con queso parmesano, crutones, tomates y aderezo cèsar.",
            "PICAÑA EN TIRAS",
            "300 gr de Jugosa Picaña acompañada de queso fundido y dip de hierbas aromáticas.",
            "RIB EYE DE LA CASA",
            "Suave Rib Eye 350 gr acompañado de delicioso guacamole natural y jalapeño al grill relleno de queso crema y tocino.",
            "CHEESECAKE DE ZARZAMORAS",
          ],
          img: "happy-valentine-day4.jpeg",
          price: 1200,
        },
        */
        {
          title: "Paquete Cumpleañero",
          desc: "Para 4 personas.",
          content: [
            "Arrachera (300 gr) bañado en camarones con queso.",
            "Picaña (300 gr) bañado en camarones con queso.",
            "Boneless BBQ o Búfalo (360 gr).",
            "4 elotes al grill con mantequilla.",
            "2 porciones de papas a la francesa.",
            "2 porciones de guacamole.",
            "Salsas tatemadas (roja y verde).",
            "Valido en la semana de tu cumpleaños.",
            "Limitado a 3 paquete por mesa.",
          ],
          img: "paquete-cumpleanero.webp",
          price: 999,
        },
        {
          title: "2 Cortes Ribeye",
          desc: "Válido únicamente en Julio de 2024.",
          content: [
            "2 Cortes Ribeye (350 grs c/u).",
            "2 Elotes al grill con mantequilla.",
            "2 Guacamoles.",
            "Salsa roja y verde tatemadas.",
            "Tortillas.",
          ],
          img: "2-ribeye.webp",
          price: 599,
        },

        {
          title: "Enamórate Con Nosotros",
          desc: "",
          content: [
            "Entrada: Guacamole Con Chicharrones De Picaña.",
            "Entrada: Ensalada Fresca Con Aderezo Mostaza-Miel.",
            "Plato Fuerte: Arrachera De La Costa.",
            "Plato Fuerte: Picaña A Las Finas Hierbas.",
            "Postre: Volcán De Chocolate En Crema De Hierbabuena.",
            "Bebida: Gin De Frutos Del Bosque, Copa De Vino Tinto, Cerveza, Limonada O Refresco. Una Por Persona.",
          ],
          img: "enamorate-con-nosotros.webp",
          price: 1500,
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.card-shadow {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
.card-image {
  border-bottom: 2px solid #eaecee;
}
.card-footer-item {
  margin: 0 0.25rem;
}

@media only screen and (max-width: 768px) {
  .columns {
    display: flex;
  }
}
</style>
